<template>
  <component
    :is="item.component"
    class="c1"
    :id="item.ref"
    :class="item.css"
    :to="item.to"
    :src="item.src"
    :params="item.params"
  >
    {{ item.content }}
    <component-builder
      :item="subitem"
      v-for="subitem in item.items"
      :key="subitem._id"
    >
      {{ subitem.content }}}
    </component-builder>
  </component>
</template>

<script>
// import singleProduct from "./singleProduct";
// import row from "./row";
// import btnlink from "./btnlink";
// import customImage from "./image";
// import productsMenu from "../pages/Home";
// import cart from "../pages/Cart.vue";
// import delivery from "../pages/Zones.vue";
// import lastProducts from "./lastProducts";
// import embedVideo from "./embedVideo";
// import productList from '../pages/Products.vue'
// import instagramFeed from './instagramFeed.vue'
// import mostProducts from './mostProducts.vue'
// import redirect from './redirect.vue'
// import category from './category.vue'
// import onlycategory from './onlyCategory.vue'
// import feedback from './feedback.vue'

const singleProduct = () => import(/* webpackChunkName: "singleproduct" */"./singleProduct.vue");
const row = () => import(/* webpackChunkName: "row" */"./row.vue");
const btnlink = () => import(/* webpackChunkName: "btnlink" */"./btnlink.vue");
const customImage = () => import(/* webpackChunkName: "image" */"./image.vue");
const productsMenu = () => import(/* webpackChunkName: "productsMenu" */"../pages/Home.vue");
const cart = () => import(/* webpackChunkName: "cart" */"../pages/Cart.vue");
const delivery = () => import(/* webpackChunkName: "delivery" */"../pages/Zones.vue");
const lastProducts = () => import(/* webpackChunkName: "lastProducts" */"./lastProducts.vue");
const embedVideo = () => import(/* webpackChunkName: "embedVideo" */"./embedVideo.vue");
const productList = () => import(/* webpackChunkName: "productList" */'../pages/Products.vue');
const instagramFeed = () => import(/* webpackChunkName: "instagramFeed" */'./instagramFeed.vue');
const mostProducts = () => import(/* webpackChunkName: "mostProducts" */'./mostProducts.vue');
const redirect = () => import(/* webpackChunkName: "redirect" */'./redirect.vue');
const category = () => import(/* webpackChunkName: "category" */'./category.vue');
const onlycategory = () => import(/* webpackChunkName: "onlycategory" */'./onlyCategory.vue');
const feedback = () => import(/* webpackChunkName: "feedback" */'./feedback.vue');


export default {
  components: {
    feedback,
    singleProduct,
    row,
    btnlink,
    customImage,
    productsMenu,
    cart,
    delivery,
    lastProducts,
    embedVideo,
    productList,
    instagramFeed,
    mostProducts,
    redirect,
    category,
    onlycategory,
    column6: {
      template: '<div class="col-12 col-md-6"><slot></slot></div>',
    },
    column4: {
      template: '<div class="col-12 col-xs-4 col-md-4"><slot></slot></div>',
    },
    column3: {
      template: '<div class="col-12 col-md-3"><slot></slot></div>',
    },
    column: {
      template: '<div class="col-12 col-md-12"><slot></slot></div>',
    },
  },
  name: "ComponentBuilder",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {

  }
};
</script>